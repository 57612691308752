import { useCallback, useState } from "react";

export default function useModal(): {
  isModalOpen: boolean;
  onCloseModal: () => void;
  onOpenModal: () => void;
} {
  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const onCloseModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return {
    isModalOpen,
    onCloseModal,
    onOpenModal,
  };
}
