export enum RequestValueType {
  DifferentUnit = "differentUnit",
  Budget = "budget",
}

export type NeedSomethingElseRequestCopyConfigType = {
  requestValue: RequestValueType | `differentTerm${string}`;
  copy: string;
};

export type ChipItemCountType =
  | {
      actionItemCount?: number;
      needSomethingElseCount?: number;
    }
  | undefined;

const needSomethingElseRequestCopyConfig: NeedSomethingElseRequestCopyConfigType[] =
  [
    {
      requestValue: RequestValueType.DifferentUnit,
      copy: "Resident requesting different unit",
    },
    {
      requestValue: RequestValueType.Budget,
      copy: "Resident has budget question",
    },
  ];

export const determineNeedSomethingElseCopy = (
  requestValue: string
): string => {
  if (requestValue.startsWith("differentTerm")) {
    return "Resident requesting different term";
  }

  const copyConfigItem = needSomethingElseRequestCopyConfig.find(
    (item) => item.requestValue === requestValue
  );

  return copyConfigItem
    ? copyConfigItem.copy
    : "Resident has a general question";
};
