import {
  ResolveNeedSomethingElseMutation,
  ResolveNeedSomethingElseMutationVariables,
} from "@/graphql/generated";
import { gql, TypedDocumentNode } from "@apollo/client";

const RESOLVE_NEED_SOMETHING_ELSE: TypedDocumentNode<
  ResolveNeedSomethingElseMutation,
  ResolveNeedSomethingElseMutationVariables
> = gql`
  mutation ResolveNeedSomethingElse(
    $resolveNeedSomethingElseInput: ResolveNeedSomethingElseInput!
  ) {
    resolveNeedSomethingElse(input: $resolveNeedSomethingElseInput) {
      id
      resolutionNotes
      resolvedAt
    }
  }
`;

export default RESOLVE_NEED_SOMETHING_ELSE;
