/* eslint-disable @typescript-eslint/no-floating-promises */
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import GET_RENEWAL_OFFERS from "@/graphql/queries/renewalOffers/getRenewalOfferBatches";
import { withoutNulls } from "@/utils/withoutNulls";
import normalizeRenewalOffers from "@/model/RenewalOffers";

export default function useRenewalOffers({ batchId }: { batchId?: string }) {
  const {
    data: raw,
    loading,
    error,
  } = useQuery(GET_RENEWAL_OFFERS, {
    variables: {
      batchId,
    },
    skip: !batchId,
  });

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.renewalOffers);
    return data && normalizeRenewalOffers(data);
  }, [raw]);

  return { data: normalized, isLoading: loading, error };
}
