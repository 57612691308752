import { gql, TypedDocumentNode } from "@apollo/client";
import { RenewalOfferFragment } from "../generated";

const RENEWAL_OFFER_FRAGMENT: TypedDocumentNode<RenewalOfferFragment> = gql`
  fragment RenewalOffer on RenewalOffer {
    id
    createdAt
    lastViewedAt
    workflowStatus
    needSomethingElse {
      id
      createdAt
      needs
      additionalInfo
      resolvedAt
    }
    unitUpgrade {
      id
      createdAt
      unit {
        id
        marketingName
        buildingName
      }
    }
    decision {
      ... on RenewalOfferDecision {
        id
        decision
        interestedTerm {
          lengthInMonths
          baseRent
        }
        decidedAt
        eSignature {
          id
          status
        }
      }
      ... on ExternalRenewalOfferDecision {
        id
        decision
        externalDecidedAt: decidedAt
        externalInterestedTerm: interestedTerm {
          lengthInMonths
          baseRent
        }
      }
    }
    lease {
      id
      noticeDate
      toDate
      moveOutInfo {
        moveOutDate
      }
      unit {
        id
        marketingName
        buildingName
        community {
          id
          name
        }
      }
    }
  }
`;

export default RENEWAL_OFFER_FRAGMENT;
