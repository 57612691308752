import useActionItems from "@/graphql/hooks/actionItems/useActionItems";
import useResolveNeedSomethingElse from "@/graphql/hooks/resolveNeedSomethingElse/useResolveNeedSomethingElse";

export default function useConnect() {
  const { data, isLoading, error } = useActionItems();
  const { resolveNeedSomethingElse, loading: resolveLoading } =
    useResolveNeedSomethingElse();
  const { totalCount, actionItems } = data || {};
  return {
    totalCount,
    actionItems,
    isLoading,
    error,
    resolveNeedSomethingElse,
    resolveLoading,
  };
}
