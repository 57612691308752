import { ResolveNeedSomethingElseInput } from "@/graphql/generated";
import RESOLVE_NEED_SOMETHING_ELSE from "@/graphql/mutations/resolveNeedSomethingElse/resolveNeedSomethingElse";
import GET_ACTION_ITEMS from "@/graphql/queries/actionItems/getActionItems";
import { gql, Reference, useMutation } from "@apollo/client";
import { useCallback } from "react";

export default function useResolveNeedSomethingElse() {
  const [submit, { loading }] = useMutation(RESOLVE_NEED_SOMETHING_ELSE, {
    refetchQueries: [
      {
        query: GET_ACTION_ITEMS,
      },
    ],
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true,
    update: (cache, { data }) => {
      const resolvedItem = data?.resolveNeedSomethingElse;
      if (resolvedItem) {
        cache.modify({
          fields: {
            needSomethingElse(
              existingItems: Reference | (Reference | undefined)[] = []
            ) {
              return Array.isArray(existingItems)
                ? existingItems.map((item) =>
                    // eslint-disable-next-line no-underscore-dangle
                    item?.__ref === `NeedSomethingElse:${resolvedItem.id}`
                      ? cache.writeFragment({
                          data: resolvedItem,
                          fragment: gql`
                            fragment NewNeedSomethingElse on NeedSomethingElse {
                              id
                              resolutionNotes
                              resolvedAt
                            }
                          `,
                        })
                      : item
                  )
                : existingItems;
            },
          },
        });
      }
    },
  });

  const resolveNeedSomethingElse = useCallback(
    async ({
      resolveNeedSomethingElseInput,
    }: {
      resolveNeedSomethingElseInput: ResolveNeedSomethingElseInput;
    }) => {
      const response = await submit({
        variables: {
          resolveNeedSomethingElseInput,
        },
      });

      return response.data?.resolveNeedSomethingElse;
    },
    [submit]
  );

  return { resolveNeedSomethingElse, loading };
}
