import styled from "@emotion/styled";
import PageHeader from "@/components/PageHeader";
import UtilizeSpacer from "@/styles/theme-styles";
import { TableCell, TableContainer, TableHead } from "@mui/material";
import DefaultCard from "@/components/Card";
import defaultTheme from "@/styles/themes/default";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import Link from "next/link";

const findPercentage = (pixels: number) => {
  const screenWidth = 1440; // width of figma mocks;
  return (pixels / screenWidth) * 100;
};

export const Wrapper = styled.div<{ $noActionItems?: boolean }>`
  height: 300px;
  display: block;
  width: 66%;

  ${(props) =>
    props.$noActionItems &&
    `
    height: auto;
  `}
`;

export const Container = styled(TableContainer)`
  height: 100%;
  max-height: 300px;
  display: block;
  border-radius: 16px;
  border: 1px solid ${defaultTheme.renewTheme.colors.grey300};
  ${UtilizeSpacer({
    options: [
      {
        size: "2xs",
        direction: [0],
      },
      {
        size: "xs",
        direction: [2],
      },
    ],
    type: "padding",
  })}
  ${UtilizeSpacer({
    options: [
      {
        size: "0",
        direction: [0],
      },
      {
        size: "sm",
        direction: [3],
      },
      {
        size: "xs",
        direction: [1, 2],
      },
    ],
    type: "margin",
  })}
  box-shadow: none;
` as typeof TableContainer;

export const CellLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  color: ${defaultTheme.renewTheme.colors.night400};
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 2px;
  display: block;
`;
export const ModalLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${defaultTheme.renewTheme.colors.night400};
  font-weight: 500;
  line-height: 18px;
  padding-bottom: 2px;
  display: block;
`;

export const Header = styled(PageHeader)``;

export const EmptyStateCard = styled(DefaultCard)`
  background-color: ${defaultTheme.renewTheme.colors.arctic25};
  justify-content: center;
  max-width: 100%;
  width: 100%;
`;

export const NoOffersCard = styled(DefaultCard)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  margin: auto;
  p {
    color: ${defaultTheme.renewTheme.colors.grey600};
    font-family: "Mint Grotesk";
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  height: 10px;
  width: 10px;
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "right",
    },
    type: "margin",
  })}
`;

export const AttachEmailIcon = styled(AttachEmailOutlinedIcon)`
  height: 10px;
  width: 10px;
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "right",
    },
    type: "margin",
  })}
`;

export const Cell = styled(TableCell)<{ $urgent?: boolean; $header?: boolean }>`
  width: ${findPercentage(236)}%;
  ${UtilizeSpacer({
    options: [
      {
        size: "0",
        direction: [2, 4],
      },
      {
        size: "3xs",
        direction: [1, 3],
      },
    ],
    type: "padding",
  })}
  *:not(a) {
    color: ${defaultTheme.renewTheme.colors.grey600};
    ${(props) =>
      props.$urgent &&
      `
    color: ${defaultTheme.renewTheme.colors.error600};
  `}
  }
  ${(props) =>
    props.$header &&
    `
    color: ${defaultTheme.renewTheme.colors.black};
    font-weight: 600;
  `}
`;
export const Action = styled.span`
  width: fit-content;
  display: flex;
  font-weight: 500;

  align-items: center;
`;

export const TableHeader = styled(TableHead)`
  * {
    font-weight: 600;
  }
`;

export const ActionAmount = styled.div`
  margin-left: 8px;
`;

export const ModalContentWrapper = styled.div`
  text-align: left;
  ${UtilizeSpacer({
    specificOption: {
      size: "xs",
      direction: "top",
    },
    type: "margin",
  })}
  color: ${defaultTheme.renewTheme.colors.grey600};
`;

export const RequestItem = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "3xs",
      direction: "bottom",
    },
    type: "margin",
  })}
  span {
    color: ${defaultTheme.renewTheme.colors.black};
  }
`;
