import { GridRowId, GridToolbar } from "@mui/x-data-grid";
import { ReactNode } from "react";
import * as Styled from "./styles";
import { TableProps } from "./types";

export default function Table({
  data,
  className,
  rowClassName,
  tableTitle,
  setSortModel,
  sortModel,
  noDataComponent,
  accordion = false,
  accordionOpen = false,
  onAccordionChange,
  hidePagination = false,
  onRowClick,
  autosizeOptions,
}: TableProps) {
  const { rows, columns } = data;

  const Wrapper = (children: ReactNode) =>
    accordion ? (
      <Styled.Expander
        $noData={!rows.length}
        expanded={rows.length ? accordionOpen : false}
        onChange={(e, expanded) =>
          onAccordionChange && onAccordionChange(e, expanded)
        }
        summary={
          <Styled.TableHeader $size="md" $weight="bold" $noData={!rows.length}>
            {tableTitle}
          </Styled.TableHeader>
        }
      >
        {rows.length ? children : noDataComponent}
      </Styled.Expander>
    ) : (
      <>
        <Styled.TableHeader $size="lg" $weight="bold" $noData={!rows.length}>
          {tableTitle}
        </Styled.TableHeader>
        {rows.length ? children : noDataComponent}
      </>
    );

  return (
    <Styled.Container>
      {Wrapper(
        <Styled.Grid
          autosizeOptions={autosizeOptions}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) =>
            setSortModel && setSortModel(newSortModel)
          }
          getRowId={
            (row) =>
              (row.id as GridRowId) ||
              (row.url as GridRowId) ||
              (row.identifiers.id as GridRowId) // eslint-disable-line @typescript-eslint/no-unsafe-member-access
          }
          pageSizeOptions={[10, 25, 100]}
          className={className}
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          autoHeight
          disableRowSelectionOnClick
          rows={rows}
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          getRowClassName={rowClassName}
          slots={{
            toolbar: GridToolbar,
          }}
          hideFooterPagination={hidePagination}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
          onRowClick={onRowClick}
        />
      )}
    </Styled.Container>
  );
}
