import * as Styled from "./styles";

const LoaderDots: React.FC = () => (
  <Styled.LoaderIcon aria-label="Loading">
    <Styled.LoaderDot />
    <Styled.LoaderDot />
    <Styled.LoaderDot />
  </Styled.LoaderIcon>
);

export default LoaderDots;
