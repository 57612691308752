/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { RenewalOfferWorkflowStatus } from "@/graphql/generated";
import InProgress from "@/assets/images/svgs/InProgress";
import AwaitingDecision from "@/assets/images/svgs/AwaitingDecision";
import * as Styled from "./styles";

export const workflowStatusAndIconMap = [
  {
    workflowStatusFilter: "All renewals",
    workflowStatus: null,
    icon: null,
  },
  {
    workflowStatusFilter: "In progress",
    workflowStatus: RenewalOfferWorkflowStatus.InProgress,
    icon: <InProgress />,
  },
  {
    workflowStatusFilter: "Awaiting Decision",
    workflowStatus: RenewalOfferWorkflowStatus.AwaitingDecision,
    icon: <AwaitingDecision />,
  },
  {
    workflowStatusFilter: "Sent",
    workflowStatus: RenewalOfferWorkflowStatus.Sent,
    icon: <Styled.SentIcon />,
  },
  {
    workflowStatusFilter: "Complete",
    workflowStatus: RenewalOfferWorkflowStatus.Completed,
    icon: <Styled.CompleteIcon />,
  },
];

export const generateTableTitle = (
  icon: React.ReactNode,
  text: string,
  actionItemCount?: number,
  residentMessageCount?: number
) => (
  <>
    {icon} {text}
    {actionItemCount && actionItemCount > 0 ? (
      <Styled.Highlight $size="2xs" $type="actions">
        {actionItemCount} action item{actionItemCount === 1 ? "" : "s"}
      </Styled.Highlight>
    ) : (
      ""
    )}
    {residentMessageCount && residentMessageCount > 0 ? (
      <Styled.Highlight $size="2xs" $type="messages">
        {residentMessageCount} resident message
        {residentMessageCount === 1 ? "" : "s"}
      </Styled.Highlight>
    ) : (
      ""
    )}
  </>
);
