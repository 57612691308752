import { Bold } from "@/components/Typography";
import { TextField } from "@mui/material";
import { memo, Dispatch, SetStateAction, useState, useCallback } from "react";
import Modal from "@/components/Modal";
import LoaderDots from "@/components/LoaderDots";
import {
  RequestItemProps,
  ModalContentProps,
  IndividualActionItem,
} from "../types";
import * as Styled from "../styles";
import useConnect from "../connect";

const NotesField = memo(
  ({
    setSubmittedNotes,
    error,
  }: {
    setSubmittedNotes: Dispatch<SetStateAction<string>>;
    error: boolean;
  }) => {
    const [localNotes, setLocalNotes] = useState("");
    const handleNotesSubmit = useCallback(
      (notes: string) => {
        setSubmittedNotes(notes);
      },
      [setSubmittedNotes]
    );
    return (
      <TextField
        multiline
        rows={4}
        fullWidth
        value={localNotes}
        onChange={(e) => setLocalNotes(e.target.value)}
        placeholder="Add your notes..."
        style={{ marginTop: "20px" }}
        onBlur={() => handleNotesSubmit(localNotes)}
        error={error}
        helperText={error && "Please add notes before marking as complete"}
      />
    );
  }
);

const RequestItem = ({ label, content }: RequestItemProps) => (
  <Styled.RequestItem>
    <Bold>{label}: </Bold>
    {content}
  </Styled.RequestItem>
);

const ModalContent = ({ request, additionalInfo }: ModalContentProps) => {
  const nseMapping = {
    maintenance: "Outstanding maintenance request",
    budget: "Budget concerns",
    unsure: "I don’t know if I’m planning to renew yet",
    differentUnit: "Looking to change apartments",
    rentableItems: "Want to update rentable items / fees on my lease",
    differentTerm: "Requesting a different renewal term",
    lookingToMove: "I'm looking to move to a new apartment",
    other: "Other",
  };
  const trimmedRequest = request?.split(",").map((item) => item.trim())[0];
  const diffTerms = {
    differentTermOne: "1 month",
    differentTermTwo: "2 months",
    differentTermThree: "3 months",
    differentTermFour: "4 months",
    differentTermFive: "5 months",
    differentTermSix: "6 months",
    differentTermSeven: "7 months",
    differentTermEight: "8 months",
    differentTermNine: "9 months",
    differentTermTen: "10 months",
    differentTermEleven: "11 months",
    differentTermTwelve: "12 months",
    differentTermThirteen: "13 months",
    differentTermFourteen: "14 months",
    differentTermFifteen: "15 months",
    differentTermSixteen: "16 months",
    differentTermSeventeen: "17 months",
    differentTermEighteen: "18 months",
    differentTermNineteen: "19 months",
    differentTermTwenty: "20 months",
    differentTermTwentyOne: "21 months",
    differentTermTwentyTwo: "22 months",
    differentTermTwentyThree: "23 months",
    differentTermTwentyFour: "24 months",
  };
  const need =
    trimmedRequest && Object.keys(diffTerms).includes(trimmedRequest)
      ? nseMapping.differentTerm
      : nseMapping[trimmedRequest as keyof typeof nseMapping];
  return (
    <Styled.ModalContentWrapper>
      {need && <RequestItem label="Request" content={<div>{need}</div>} />}
      {additionalInfo && <RequestItem label="Notes" content={additionalInfo} />}
    </Styled.ModalContentWrapper>
  );
};

const NSEModal = ({
  isModalOpen,
  onCloseModal,
  selectedNSE,
}: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  selectedNSE: IndividualActionItem["needSomethingElse"] | undefined;
}) => {
  const { resolveNeedSomethingElse, resolveLoading } = useConnect();
  const [submittedNotes, setSubmittedNotes] = useState("");
  const [error, setError] = useState(false);
  return (
    <Modal
      isModalOpen={isModalOpen}
      onCloseModal={() => {
        onCloseModal();
        setError(false);
      }}
      title="Review resident request and mark as complete"
      subtitle="Add notes about resident request below. Please note that this will only mark resident message as complete. You will need to followup with resident to update their lease with any changes"
      primaryButton={{
        label: resolveLoading ? <LoaderDots /> : "Mark as complete",
        onClick: () => {
          if (!submittedNotes.trim()) {
            setError(true);
          } else {
            setError(false);
            void resolveNeedSomethingElse({
              resolveNeedSomethingElseInput: {
                id: selectedNSE?.id || "",
                notes: submittedNotes,
              },
            }).then(() => {
              onCloseModal();
              setSubmittedNotes("");
            });
          }
        },
      }}
    >
      <ModalContent
        request={selectedNSE?.mostRecent}
        additionalInfo={selectedNSE?.additionalInfo}
      />
      <NotesField error={error} setSubmittedNotes={setSubmittedNotes} />
    </Modal>
  );
};

export default memo(NSEModal);
