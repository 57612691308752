import useCurrentUser from "@/graphql/hooks/user/useUser";
import { JSX } from "@emotion/react/jsx-runtime";
import DropdownFilters from "./partials/DropdownFilters";
import * as Styled from "./styles";
import useConnect from "./connect";
import StatusTables from "./partials/StatusTables";
import ActionItems from "./partials/ActionItems";

const RenewalOffers = () => {
  const {
    selectedWorkflowStatusFilters,
    handleWorkflowStatusFilterClick,
    isWorkflowStatusFilterActive,
    workflowStatusAndIconMap,
  } = useConnect();
  const { data: user } = useCurrentUser();
  const widgetBuildout = user?.enabledFeatures.includes(
    "PARTNER_APP_ACTION_ITEMS"
  );

  const wrapper = (children: JSX.Element) =>
    widgetBuildout ? (
      <>
        <Styled.Header title="Renewal Offers Overview" />
        <ActionItems />
        <Styled.RmsContainer>{children}</Styled.RmsContainer>
      </>
    ) : (
      children
    );
  return (
    <Styled.Container>
      {wrapper(
        <>
          <Styled.Header size="small" title="Renewal Offers">
            {workflowStatusAndIconMap.map(({ workflowStatusFilter }) => (
              <Styled.FilterItem
                key={workflowStatusFilter}
                label={workflowStatusFilter}
                small
                variant={
                  isWorkflowStatusFilterActive(workflowStatusFilter)
                    ? "filled"
                    : "outlined"
                }
                onClick={() =>
                  handleWorkflowStatusFilterClick(workflowStatusFilter)
                }
              />
            ))}
          </Styled.Header>
          <DropdownFilters />
          <StatusTables
            selectedWorkflowStatusFilters={selectedWorkflowStatusFilters}
          />
        </>
      )}
    </Styled.Container>
  );
};

export default RenewalOffers;
