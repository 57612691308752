import { ReactNode } from "react";
import * as Styled from "./styles";

export default function PageHeader({
  title,
  children,
  copy,
  className,
  size = "large",
}: {
  title: string;
  children?: ReactNode;
  copy?: string | ReactNode;
  className?: string;
  size?: "large" | "small";
}) {
  return (
    <Styled.Container className={className}>
      <Styled.TitleWrapper>
        {size === "small" ? (
          <Styled.TitleSmall $size="xl" $weight="bold">
            {title}
          </Styled.TitleSmall>
        ) : (
          <Styled.Title>{title}</Styled.Title>
        )}
        {children}
      </Styled.TitleWrapper>
      {copy && <Styled.SubTitle>{copy}</Styled.SubTitle>}
    </Styled.Container>
  );
}
