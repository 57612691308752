import { useState } from "react";
import { datadogRum } from "@datadog/browser-rum";

const DatadogProvider = (): null => {
  const [datadogIsInitialized, setDatadogIsInitialized] = useState(false);
  if (
    !datadogIsInitialized &&
    process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true" &&
    process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN &&
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
  ) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "partner-app",
      env: process.env.NEXT_PUBLIC_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.VERCEL_GIT_COMMIT_SHA,
      sessionSampleRate: 100,
      // even if datadog is enabled in development we do not want to create session replays in development
      sessionReplaySampleRate:
        process.env.NEXT_PUBLIC_ENV === "development" ? 0 : 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: ["https://renew-partner-app.vercel.app"],
    });

    datadogRum.startSessionReplayRecording();
    setDatadogIsInitialized(true);
  }
  return null;
};

export default DatadogProvider;
