import { from } from "@/styles/responsive";
import addAlpha from "@/utils/addAlpha";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import defaultTheme from "@/styles/themes/default";
import UtilizeSpacer from "@/styles/theme-styles";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Body1 } from "../Typography";
import Button from "../Button";

const createBottomMargin = (
  size: "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl"
) =>
  UtilizeSpacer({
    specificOption: { size, direction: "bottom" },
    type: "margin",
  });

const { night400, arctic400, grey500 } = defaultTheme.renewTheme.colors;

export const Root = styled(Dialog)`
  & .MuiPaper-elevation24 {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  }

  & .MuiPaper-root {
    border-radius: 20px 20px 0 0;
    width: 100%;

    ${from.tabletLandscape`
      border-radius: 20px;
       max-width: 571px;
    `};
  }

  & .MuiBackdrop-root {
    background: ${addAlpha(grey500, 0.25)};
  }

  & .MuiDialog-paper {
    margin: 0;
  }

  & .MuiDialog-scrollPaper {
    align-items: flex-end;

    ${from.tabletLandscape`
      padding: ${defaultTheme.renewTheme.spacing.desktop.md};
      align-items: center;
    `};
  }

  & .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }

  & .MuiDialog-paperWidthSm {
    max-width: none;
    ${from.tabletLandscape`
        max-width: 571px;
    `};
  }
`;

export const ModalContent = styled.div`
  padding: 32px;
  text-align: center;
`;

export const Title = styled(Body1)`
  ${createBottomMargin("3xs")}
`;

export const ButtonWrapper = styled.div`
  ${UtilizeSpacer({
    specificOption: {
      size: "sm",
      direction: "top",
    },
    type: "margin",
  })}
`;

export const ModalButtons = styled(Button)`
  width: 100%;
  &:not(:last-child) {
    ${createBottomMargin("2xs")}
  }
`;

export const Icon = styled(NoteAddOutlinedIcon)`
  height: 24px;
  width: 24px;
  fill: ${night400};
`;

export const IconWrapper = styled.div`
  background-color: ${arctic400};
  height: 56px;
  width: 56px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${createBottomMargin("md")}
`;
