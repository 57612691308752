import { ActionItem, ActionItemType } from "@/graphql/generated";
import type { WithoutNulls } from "@/utils/withoutNulls";

export default function normalizeActionItems(
  input: WithoutNulls<ActionItem[]>
): {
  id: string;
  type?: ActionItemType;
  unit: {
    id?: string;
    name?: string;
    buildingName?: string;
    communityName?: string;
  };
  lease: {
    toDate?: string;
  };
  needSomethingElse: {
    id?: string;
    mostRecent?: string;
    additionalInfo?: string;
  };
}[] {
  return input.reduce((acc, actionItem) => {
    if (actionItem.renewalOffer?.workflowStatus !== "COMPLETED") {
      const NeedSomethingElseSorted =
        actionItem.renewalOffer?.needSomethingElse.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

      acc.push({
        id: actionItem.id,
        type: actionItem.type,
        unit: {
          id: actionItem.renewalOffer?.lease?.unit?.id,
          name: actionItem.renewalOffer?.lease?.unit?.marketingName,
          buildingName: actionItem.renewalOffer?.lease?.unit?.buildingName,
          communityName: actionItem.renewalOffer?.lease?.unit?.community?.name,
        },
        lease: {
          toDate: actionItem.renewalOffer?.lease?.toDate,
        },
        needSomethingElse: {
          id: NeedSomethingElseSorted?.[0]?.id,
          mostRecent: NeedSomethingElseSorted?.[0]?.needs[0],
          additionalInfo: NeedSomethingElseSorted?.[0]?.additionalInfo,
        },
      });
    }
    return acc;
  }, [] as ReturnType<typeof normalizeActionItems>);
}

export type ActionItems = ReturnType<typeof normalizeActionItems>;
