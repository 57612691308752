import { memo } from "react";

import { Modal as MuiModal } from "@mui/material";
import { Body1 } from "@/components/Typography";
import * as Styled from "./styles";
import type { Props } from "./types";

interface ModalButtonsProps {
  primaryButton: {
    onClick: () => void;
    label: string | JSX.Element;
  };
  onCloseModal: () => void;
}

const ModalButtons = ({ primaryButton, onCloseModal }: ModalButtonsProps) => (
  <Styled.ButtonWrapper>
    <Styled.ModalButtons onClick={primaryButton.onClick}>
      {primaryButton.label}
    </Styled.ModalButtons>
    <Styled.ModalButtons variant="floating" onClick={onCloseModal}>
      Cancel
    </Styled.ModalButtons>
  </Styled.ButtonWrapper>
);

const Modal = ({
  children,
  className,
  isModalOpen,
  onCloseModal,
  title,
  subtitle,
  primaryButton,
}: Props): JSX.Element => (
  <MuiModal open={isModalOpen} className={className}>
    <Styled.Root open={isModalOpen}>
      <Styled.ModalContent>
        <Styled.IconWrapper>
          <Styled.Icon />
        </Styled.IconWrapper>
        <Styled.Title $size="xl" $weight="bold">
          {title}
        </Styled.Title>
        <Body1>{subtitle}</Body1>
        {children}
        <ModalButtons
          primaryButton={primaryButton}
          onCloseModal={onCloseModal}
        />
      </Styled.ModalContent>
    </Styled.Root>
  </MuiModal>
);

export default memo(Modal);
