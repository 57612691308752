/* eslint-disable import/no-extraneous-dependencies */
import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import defaultTheme from "@/styles/themes/default";
import UtilizeSpacer from "@/styles/theme-styles";
import addAlpha from "@/utils/addAlpha/index";
import { Body1 } from "@/components/Typography";
import Accordion from "../Accordion";

export const Container = styled.div``;

export const Grid = styled(DataGrid)`
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  .MuiDataGrid-footerContainer {
    border: 0;
  }
  border: 0;

  .MuiDataGrid-container--top [role="row"] {
    background: transparent !important;
  }
  .MuiDataGrid-main {
    border-top: 0;
  }

  .MuiDataGrid-overlayWrapper {
    height: 179px !important;
  }

  .renewalOffer-theme--header {
    color: ${defaultTheme.renewTheme.colors.grey600};
    border: 0;
    padding: 0px 1.25rem;
    border-bottom: 1px solid ${defaultTheme.renewTheme.colors.grey300};
    font-family: "Mint Grotesk";
    font-size: 12px;
    font-style: normal;

    line-height: 18px;
    &.MuiDataGrid-columnHeader--sorted {
      color: ${defaultTheme.renewTheme.colors.night400};
      .MuiDataGrid-sortIcon {
        fill: ${defaultTheme.renewTheme.colors.night400};
      }
    }
    * {
      font-weight: 600;
    }

    .MuiDataGrid-columnSeparator {
      &--sideRight {
        display: none;
      }
    }

    .MuiDataGrid-sortIcon {
      opacity: 1 !important;
    }

    color: ${defaultTheme.renewTheme.colors.black};
  }

  .renewalOffer-theme--cell {
    &:nth-child(2) {
      padding: 16px 2rem !important;
    }
    padding: 16px 1.25rem;
    line-height: 18px;
    font-size: 12px;
    color: ${defaultTheme.renewTheme.colors.grey600};
    font-weight: 500;
    display: flex;
    align-items: center;

    &__daysTill {
      color: ${defaultTheme.renewTheme.colors.night900};
      &__success {
        background-color: ${addAlpha(
          defaultTheme.renewTheme.colors.success300,
          0.5
        )};
      }
      &__medium {
        background-color: ${addAlpha(
          defaultTheme.renewTheme.colors.warning300,
          0.3
        )};
      }
      &__urgent {
        background-color: ${addAlpha(
          defaultTheme.renewTheme.colors.error300,
          0.3
        )};
      }
    }
  }
`;

export const TableHeader = styled(Body1)<{ $noData?: boolean }>`
  display: flex;
  color: ${defaultTheme.renewTheme.colors.black};
  margin: auto 0;
  svg,
  path {
    height: 100%;
    width: 100%;
    max-height: 24px;
    max-width: 24px;
    margin-right: 8px;
  }
  ${UtilizeSpacer({
    specificOption: {
      size: "sm",
      direction: "left",
    },
    type: "padding",
  })}

  ${({ $noData }) =>
    $noData &&
    `
      color: ${defaultTheme.renewTheme.colors.grey600};
  `}
`;

export const Expander = styled(Accordion)<{ $noData?: boolean }>`
  &.MuiAccordion-root {
    ${({ $noData }) =>
      $noData &&
      `svg, path {
    fill: ${defaultTheme.renewTheme.colors.grey600};
    }
  `}
  }
`;
