import { gql, TypedDocumentNode } from "@apollo/client";
import { ActionItemFragment } from "../generated";
import RENEWAL_OFFER_FRAGMENT from "./renewalOffer";

const ACTION_ITEM_FRAGMENT: TypedDocumentNode<ActionItemFragment> = gql`
  fragment ActionItem on ActionItem {
    id
    type
    renewalOffer {
      ...RenewalOffer
    }
  }
  ${RENEWAL_OFFER_FRAGMENT}
`;

export default ACTION_ITEM_FRAGMENT;
