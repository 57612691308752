import { Paper, Table, TableRow, TableBody } from "@mui/material";
import { Body1 } from "@/components/Typography";
import { determineNeedSomethingElseCopy } from "@/utils/determineNeedSomethingElseCopy";
import ToolTip from "@/components/Tooltip";
import { differenceInDays } from "date-fns";
import { ActionItemType } from "@/graphql/generated";
import useModal from "@/hooks/useModal";
import { useState } from "react";
import Image from "next/image";
import confirmation from "@/assets/images/illustrations/confirmation.png";

import { LoaderImage } from "@/components/Loader/styles";
import * as Styled from "./styles";
import useConnect from "./connect";
import { ActionProps, IndividualActionItem } from "./types";
import NSEModal from "./NSEModal";

const daysTillLeaseEndsGenerator = (toDate?: string) =>
  toDate ? differenceInDays(toDate, new Date()) : 0;

const ActionComponent = ({
  tooltipContent,
  actionText,
  icon,
  daysTillLeaseEnds,
}: ActionProps) => {
  const wrapper = (children: JSX.Element) =>
    tooltipContent ? (
      <ToolTip popperContent={tooltipContent}>{children}</ToolTip>
    ) : (
      <>{children}</>
    );

  return (
    <>
      <Body1 $size="xs" $weight="medium">
        {wrapper(
          <Styled.Action>
            {icon}
            {actionText}
          </Styled.Action>
        )}
      </Body1>
      <Body1 $size="2xs">
        {daysTillLeaseEnds < 0
          ? `Lease ended ${Math.abs(daysTillLeaseEnds)} ${
              Math.abs(daysTillLeaseEnds) <= 1 ? "day" : "days"
            } ago`
          : `Lease ends in ${daysTillLeaseEnds} ${
              daysTillLeaseEnds <= 1 ? "day" : "days"
            }`}
      </Body1>
    </>
  );
};

const renderAction = (row: IndividualActionItem) => {
  if (row.type === ActionItemType.EsignedNoticeToVacate) {
    return (
      <ActionComponent
        icon={<Styled.InfoIcon />}
        tooltipContent="You must counter-sign resident documents to complete lease"
        actionText="Counter-sign: Notice to vacate"
        daysTillLeaseEnds={daysTillLeaseEndsGenerator(row.lease.toDate)}
      />
    );
  }
  if (row.needSomethingElse.mostRecent) {
    return (
      <ActionComponent
        icon={<Styled.AttachEmailIcon />}
        actionText={determineNeedSomethingElseCopy(
          row.needSomethingElse.mostRecent
        )}
        daysTillLeaseEnds={daysTillLeaseEndsGenerator(row.lease.toDate)}
      />
    );
  }
  return null;
};

const ActionItems = () => {
  const { actionItems, totalCount, isLoading } = useConnect();

  // Sort actionItems by renewalOffer.lease.toDate
  const sortedActionItems = actionItems
    ?.filter((item) => item.lease?.toDate)
    .sort((a, b) => {
      const dateA = new Date(a.lease?.toDate ?? new Date());
      const dateB = new Date(b.lease?.toDate ?? new Date());
      return dateA.getTime() - dateB.getTime();
    });

  const { isModalOpen, onOpenModal, onCloseModal } = useModal();
  const [selectedNSE, setSelectedNse] =
    useState<IndividualActionItem["needSomethingElse"]>();

  const noActionItems = !isLoading && actionItems?.length < 1;

  const wrapper = (children: JSX.Element) => {
    if (noActionItems) {
      return (
        <Styled.NoOffersCard
          content="All unresolved action items complete!"
          illustration={
            <Image
              src={confirmation.src}
              alt="No offers image"
              width={74}
              height={61}
            />
          }
        />
      );
    }
    return children;
  };

  return (
    <Styled.Wrapper $noActionItems={noActionItems}>
      <Styled.Container component={Paper}>
        <NSEModal
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          selectedNSE={selectedNSE}
        />
        <Styled.Header size="small" title="Active action items">
          {totalCount && (
            <Styled.ActionAmount>
              {totalCount > 25
                ? `${actionItems.length} of ${totalCount}`
                : totalCount}
            </Styled.ActionAmount>
          )}
        </Styled.Header>
        {wrapper(
          isLoading ? (
            <LoaderImage
              style={{ margin: "auto", display: "flex", height: "100%" }}
            />
          ) : (
            <Table
              sx={{ minWidth: 100 }}
              size="small"
              aria-label="action items table"
            >
              <Styled.TableHeader>
                <TableRow>
                  <Styled.Cell $header>Unit #</Styled.Cell>
                  <Styled.Cell $header>Status</Styled.Cell>
                  <Styled.Cell />
                </TableRow>
              </Styled.TableHeader>
              <TableBody>
                {sortedActionItems?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <Styled.Cell component="th" scope="row">
                      <Styled.CellLink href={`/units/${row.unit.id || ""}`}>
                        {row.unit.buildingName && `${row.unit.buildingName} - `}
                        {row.unit.name}
                      </Styled.CellLink>
                      <Body1 $size="2xs">{row.unit.communityName}</Body1>
                    </Styled.Cell>
                    <Styled.Cell
                      $urgent={
                        daysTillLeaseEndsGenerator(row.lease.toDate) < 15
                      }
                    >
                      {renderAction(row)}
                    </Styled.Cell>
                    <Styled.Cell>
                      {row.needSomethingElse.mostRecent &&
                      row.type === ActionItemType.NeedSomethingElse ? (
                        <Styled.ModalLink
                          onClick={() => {
                            if (
                              row.needSomethingElse.mostRecent &&
                              row.type === ActionItemType.NeedSomethingElse
                            ) {
                              setSelectedNse(row.needSomethingElse);
                              onOpenModal();
                            }
                          }}
                        >
                          Resolve action item
                        </Styled.ModalLink>
                      ) : (
                        <Styled.CellLink
                          href={`/units/${
                            row.unit.id || ""
                          }/countersign?source=renewal-offers`}
                        >
                          Resolve action item
                        </Styled.CellLink>
                      )}
                    </Styled.Cell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default ActionItems;
