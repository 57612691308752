/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeActionItems from "@/model/ActionItem";
import { WithoutNulls, withoutNulls } from "@/utils/withoutNulls";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import GET_ACTION_ITEMS from "@/graphql/queries/actionItems/getActionItems";
import { ActionItem } from "@/graphql/generated";

export default function useActionItems() {
  const [getActionItems, { data: raw, loading, error }] = useLazyQuery(
    GET_ACTION_ITEMS,
    { fetchPolicy: "cache-first" }
  );

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.actionItems) as {
      totalCount: number;
      actionItems: ActionItem[];
    };
    return (
      data && {
        totalCount: data.totalCount,
        actionItems: normalizeActionItems(
          data.actionItems as WithoutNulls<ActionItem[]>
        ),
      }
    );
  }, [raw]);

  useEffect(() => {
    getActionItems();
  }, [getActionItems]);

  return { data: normalized, isLoading: loading, error };
}
