import loading from "@/styles/animations";
import styled from "@emotion/styled";

export const LoaderDot = styled.div`
  animation: ${loading} 500ms linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  bottom: 0.5rem;
  height: 0.375rem;
  position: absolute;
  width: 0.375rem;

  :nth-child(1) {
    left: -0.25rem;
  }

  :nth-child(2) {
    animation-delay: 150ms;
    left: 0.25rem;
  }
  :nth-child(3) {
    animation-delay: 250ms;
    left: 0.75rem;
  }
`;

export const LoaderIcon = styled.div`
  align-items: center;
  display: flex;
  height: 0.7rem;
  justify-content: space-between;
  position: relative;
  width: 2rem;
  text-align: center;
`;
